@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Bold.eot');
    src: url('../fonts/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gilroy-Bold.woff2') format('woff2'), url('../fonts/Gilroy-Bold.woff') format('woff'),
    url('../fonts/Gilroy-Bold.ttf') format('truetype'), url('../fonts/Gilroy-Bold.svg#Gilroy-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-SemiBold.eot');
    src: url('../fonts/Gilroy-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gilroy-SemiBold.woff2') format('woff2'), url('../fonts/Gilroy-SemiBold.woff') format('woff'),
    url('../fonts/Gilroy-SemiBold.ttf') format('truetype'),
    url('../fonts/Gilroy-SemiBold.svg#Gilroy-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Light.eot');
    src: url('../fonts/Gilroy-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gilroy-Light.woff2') format('woff2'), url('../fonts/Gilroy-Light.woff') format('woff'),
    url('../fonts/Gilroy-Light.ttf') format('truetype'), url('../fonts/Gilroy-Light.svg#Gilroy-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Medium.eot');
    src: url('../fonts/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gilroy-Medium.woff2') format('woff2'), url('../fonts/Gilroy-Medium.woff') format('woff'),
    url('../fonts/Gilroy-Medium.ttf') format('truetype'),
    url('../fonts/Gilroy-Medium.svg#Gilroy-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-ExtraBold.eot');
    src: url('../fonts/Gilroy-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gilroy-ExtraBold.woff2') format('woff2'), url('../fonts/Gilroy-ExtraBold.woff') format('woff'),
    url('../fonts/Gilroy-ExtraBold.ttf') format('truetype'),
    url('../fonts/Gilroy-ExtraBold.svg#Gilroy-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Heavy.eot');
    src: url('../fonts/Gilroy-Heavy.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gilroy-Heavy.woff2') format('woff2'), url('../fonts/Gilroy-Heavy.woff') format('woff'),
    url('../fonts/Gilroy-Heavy.ttf') format('truetype'), url('../fonts/Gilroy-Heavy.svg#Gilroy-Heavy') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Regular.eot');
    src: url('../fonts/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gilroy-Regular.woff2') format('woff2'), url('../fonts/Gilroy-Regular.woff') format('woff'),
    url('../fonts/Gilroy-Regular.ttf') format('truetype'),
    url('../fonts/Gilroy-Regular.svg#Gilroy-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

* {
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
}

body {
    margin: 0px;
    font-family: 'Gilroy';
    font-weight: 500;
    color: #7f8fa4;
    background: #f2f5fb;
}

img {
    max-width: 100%;
    height: auto;
}

input,
textarea,
button,
select,
a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.overflowHidden {
    overflow: hidden;
}

.xsShow {
    display: none;
}

.container {
    max-width: 1160px;
    padding: 0 15px;
    width: 100%;
    margin: 0 auto;
}

.header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 100;
}

.headerInner {
    display: flex;
    align-items: center;
}

.logo {
    margin-right: auto;
    padding: 21px 39px 21px 26px;
}

.mobileMenuBtn {
    display: none;
}

.menuRow > ul {
    display: flex;
    margin: 0px;
    padding: 0;
    padding: 21px 39px 21px 26px;
}

.menuRow > ul > li {
    margin-left: 74px;
    list-style: none;
    position: relative;
}

.menuRow > ul > li:first-child {
    margin-left: 0;
}

.menuRow ul > li > a {
    text-decoration: none;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #7f8fa4;
    display: flex;
    align-items: center;
    transition: color 0.5s ease-in-out;
    text-transform: uppercase;
}

.menuRow ul > li > a:hover {
    color: #5a6175;
}

.menuRow ul > li > a .downArrow {
    margin-left: 11px;
    display: inline-flex;
    align-items: center;
    margin-top: 3px;
}

.menuRow ul > li .subMenu {
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding: 0;
    position: absolute;
    top: 100%;
    right: -18px;
    padding: 11px 24px 15px;
    background: #ffffff;
    box-shadow: 0px 0px 22px 8px rgba(0, 0, 0, 0.04);
    width: 176px;
    margin-top: 12px;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.3s ease-in-out;
}

.menuRow ul > li:hover .subMenu {
    transform: scaleY(1);
}

.menuRow ul > li .subMenu:before {
    content: '';
    position: absolute;
    top: -12px;
    right: 13px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #fff;
    display: block;
    width: 0;
    z-index: 0;
}

.menuRow ul > li .subMenu li {
    list-style: none;
}

.menuRow ul > li .subMenu li a {
    color: #3b4859;
    font-weight: 500;
    font-size: 16px;
    line-height: 36px;
    transition: color 0.5s ease-in-out;
    text-transform: capitalize;
}

.menuRow ul > li .subMenu li a:hover {
    color: #e95933;
}

.userProfile {
    padding: 27px 18px 27px 14px;
    border-left: 2px solid #e5edf3;
    align-self: stretch;
    position: relative;
}

.userProfile a {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #3b4859;
    background: #fff;
    text-decoration: none;
}

.userProfile a:active {
    color: red;
    background: transparent;
    background: transparent;
    outline: 0px;
}

.userProfile a:visited,
.userProfile a:focus,
.userProfile a:focus-within {
    color: purple;
    background: transparent;
    outline: 0px;
}

.userProfile a .userThumb {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: block;
    overflow: hidden;
    margin-right: 15px;
}

.userProfile a .userThumb img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.userProfile a .downArrow {
    margin-left: 8px;
}

.profileDropdown {
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding: 0;
    position: absolute;
    top: 100%;
    right: 0;
    padding: 11px 24px 15px;
    background: #ffffff;
    box-shadow: 0px 0px 22px 8px rgba(0, 0, 0, 0.04);
    width: 176px;
    transform: scaleY(0);
    transform-origin: top;
    transition: all 0.3s ease-in-out;
}

.profileDropdown.open {
    transform: scaleY(1);
}

.profileDropdown:before {
    content: '';
    position: absolute;
    top: -12px;
    right: 13px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #fff;
    display: block;
    width: 0;
    z-index: 0;
}

.profileDropdown li {
    list-style: none;
}

.profileDropdown li a {
    color: #3b4859;
    font-weight: 500;
    font-size: 16px;
    line-height: 36px;
    transition: color 0.5s ease-in-out;
}

.profileDropdown li a:hover {
    color: #e95933;
}

.banner {
    position: relative;
}

.hmBanner.banner {
    height: 100vh;
    position: relative;
}

.banner::after {
    background: url(../images/login-banner-image.png) no-repeat left bottom;
    content: '';
    position: absolute;
    top: 130px;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 9;
    background-size: contain;
}

.banner img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.bannerText {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;
    padding-top: 100px;
    justify-content: flex-end;
    z-index: 10;
}

@media (min-width: 500px) {
    .bannerText {
        align-items: center;
        padding-top: 0px;
    }
}


.hmBanner .bannerTextWrap {
    padding-top: 246px;
    max-width: 669px;
    padding-right: 178px;
}

.bannerTextWrap {
    max-width: 766px;
    margin-left: auto;
    padding-right: 228px;
    padding-top: 90px;
}

.bannerTextWrap h1 {
    color: #5a6175;
    letter-spacing: -0.625px;
    font-weight: bold;
    font-size: 50px;
    line-height: 68px;
    margin: 0 0 3px;
}

.bannerTextWrap p {
    font-size: 26px;
    line-height: 34px;
    color: #5a6175;
    margin-bottom: 20px;
    font-weight: normal;
}

.bannerBtn {
    padding-top: 43px;
    display: flex;
    align-items: center;
}

.bannerBtn .loginBtn {
    background-image: linear-gradient(180deg, #fe7652 0%, #e95933 100%);
    background-size: 100% 100%;
    border: 1px solid #c73914;
    border-radius: 32px;
    height: 64px;
    width: 159px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 46px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    text-decoration: none;
    margin-left: 46px;
    transition: opacity 0.5s ease-in-out;
}

.bannerBtn .loginBtn:hover {
    opacity: 0.8;
}

.bannerBtn .playBtn {
    height: 70px;
    width: 70px;
    display: inline-flex;
    box-shadow: 0px 6px 12px rgba(40, 43, 49, 0.08);
    border-radius: 50%;
    position: relative;
    z-index: 10;
}

.bannerBtn .playBtn img {
    height: 100%;
    width: 100%;
}

.bannerBtn .playBtn:before,
.bannerBtn .playBtn:after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
    border: 1px solid #fff;
    border-radius: 50%;
    z-index: -1;
}

.bannerBtn .playBtn:before {
    animation: ripple 2s linear infinite;
}

.bannerBtn .playBtn:after {
    animation: ripple 2s linear 1s infinite;
}

@keyframes ripple {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.4);
        opacity: 1;
    }
    100% {
        transform: scale(1.6);
        opacity: 0;
    }
}

.videoModal {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 100;
    top: 0;
    display: none;
    background-color: rgba(0, 0, 0, 0.7);
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.videoModal.open {
    display: flex;
    opacity: 1;
}

.videoModal .modalBody {
    width: 740px;
    transition: all 0.3s ease-in-out;
    height: 410px;
    transform: scale(0.3);
    margin: 0 auto;
    position: relative;
}

.videoModal.open .modalBody {
    transform: scale(1);
}

.videoModal iframe {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: none;
}

.videoModal .closeBtn {
    position: absolute;
    top: -10px;
    cursor: pointer;
    right: -10px;
    background-color: transparent;
    border: none;
}

/***** Play List Page  *****/

.playListSection {
    padding: 57px 0 30px;
}

.sectionTitle {
    padding-bottom: 16px;
}

.sectionTitle h2 {
    font-weight: 500;
    font-size: 30px;
    line-height: 24px;
    color: #1d1f26;
    margin: 0 0 7px;
}

.sectionTitle p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7f8fa4;
}

.playListRow {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -11.5px;
}

.playListRow .playListItem {
    flex: 0 1 25%;
    padding: 0 11.5px;
}

.playListCard {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    cursor: pointer;
}

.playListCard .playListThumb {
    margin: 0 0 0;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 0px 14px rgba(211, 216, 227, 0.497623);
    height: 265px;
    transition: all 0.3s ease-in-out;
    margin-bottom: 12px;
}

.playListCard:hover .playListThumb {
    box-shadow: 0px 10px 14px rgba(211, 216, 227, 0.5);
}

.playListCard .playListThumb img {
    max-width: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.playListCard .playListDetails {
    display: flex;
    justify-content: space-between;
}

.playListCard .playListDetails .playListInfo {
    flex: 1 1 auto;
    padding-right: 10px;
}

.playListCard .playListDetails .playListInfo h2 {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #1c1e25;
    margin: 0 0 2px;
}

.playListCard .playListDetails .playListInfo p {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #7f8fa4;
    margin: 0px;
}

.playListCard .playListDetails .playListTrack p {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #1c1e25;
    margin: 0px;
}

/**** Sort By Section ****/

.sortbycontentSec {
    min-height: 100vh;
    padding-top: 90px;
    display: flex;
    flex-direction: column;
}

.sortbySec {
    padding: 29px 26px 50px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}

.secWrapper {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}

.backBtnRow {
    padding-bottom: 21px;
}

.backBtnRow a {
    display: inline-flex;
    align-items: center;
    color: #354052;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-decoration: none;
}

.backBtnRow a span {
    margin-right: 16px;
    display: inline-flex;
    align-items: center;
}

.sortByCardRow {
    flex: 1 1 auto;
    display: flex;
    align-items: stretch;
}

.sortByCardColLeft {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding-left: 30px;
}

.sortByCardColRight {
    flex: 0 0 373px;
    display: flex;
    flex-direction: column;
}

.sortByCardColRight .sortByCard {
    margin-bottom: 30px;
}

.sortByCardColRight .sortByCard.sleepCardBox {
    flex: 0 1 auto;
}

.sortByCardColRight .sortByCard:last-child {
    margin-bottom: 0px;
}

.sortByCard {
    flex: 1 1 auto;
    background: #ffffff;
    box-shadow: 0px 10px 20px #eff2f7;
    border-radius: 10px;
}

.sortByChartBox {
    padding: 0;
}

.sortBoxHead {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f4f4f4;
}

.sortBoxHead .sleepCardHead {
    padding: 25px 10px 10px 30px;
    flex: 1 1 auto;
}

.sortBoxHead .sleepCardHead h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 24px;
    color: #1d1f26;
    margin: 0px;
}

.sortBoxHead .sleepCardHead span {
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #7f8fa4;
    display: block;
}

.sleepTimeDetails {
    display: flex;
    flex: 0 0 396px;
}

.sleepTimeDetails .sleepTimeDetItem {
    padding: 15px 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    flex: 0 1 33.33%;
    border-left: 1px solid #f4f4f4;
}

.sleepTimeDetails .sleepTimeDetItem:last-child {
    border-right: 0px;
}

.sleepTimeDetails .sleepTimeDetItem h3 {
    color: #7f8fa4;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin: 0 0 2px;
}

.sleepTimeDetails .sleepTimeDetItem p {
    color: #354052;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
}

.sortByBtnList {
    padding: 21px 30px 23px;
    margin-right: -15px;
}

.sortByTitle {
    padding-bottom: 14px;
}

.sortByTitle h1 {
    font-weight: 600;
    font-size: 22px;
    line-height: 24px;
    color: #1d1f26;
    margin: 0px;
}

.sortByBtnList .btn {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #ffffff;
    border-radius: 4px;
    border: 1px solid #fff;
    height: 35px;
    margin-bottom: 10px;
    padding: 10px 25px;
    cursor: pointer;
    margin-right: 12px;
    transition: all 0.3s ease-in-out;
    outline: 0px;
}

.sortByBtnList .btn.disabled {
    opacity: 0.3;
    cursor: no-drop;
}

.sortByBtnList .btn-purple {
    background: #b149fd;
    border-color: #b149fd;
}

.sortByBtnList .btn-purple:hover {
    background: transparent;
    color: #b149fd;
}

.sortByBtnList .btn-yellow {
    background: #f7d548;
    border-color: #f7d548;
}

.sortByBtnList .btn-yellow:hover {
    background: transparent;
    color: #f7d548;
}

.sortByBtnList .btn-pink {
    background: #eb3e75;
    border-color: #eb3e75;
}

.sortByBtnList .btn-pink:hover {
    background: transparent;
    color: #eb3e75;
}

.sortByBtnList .btn-lightPink {
    background: #F241E4;
}

.sortByBtnList .btn-lightPink:hover {
    background: #de33d1;
}

.sortByBtnList .btn-specialred:hover {
    background: #e95933;
}

.sortByBtnList .btn-specialred {
    background: #FE7652;
}

.sortByBtnList .btn-green {
    background: #8dc73c;
    border-color: #8dc73c;
}

.sortByBtnList .btn-green:hover {
    background: transparent;
    color: #8dc73c;
}

.sortByBtnList .btn-lightRed {
    background: #ee7d5c;
    border-color: #ee7d5c;
}

.sortByBtnList .btn-lightRed:hover {
    background: transparent;
    color: #ee7d5c;
}

.sortByBtnList .btn-primary {
    background: #64abf9;
    border-color: #64abf9;
}

.sortByBtnList .btn-primary:hover {
    background: transparent;
    color: #64abf9;
}

.sortByBtnList .btn-lightBlue {
    background: #60d6f9;
    border-color: #60d6f9;
}

.sortByBtnList .btn-lightBlue:hover {
    background: transparent;
    color: #60d6f9;
}

.chartFillterRow {
    display: flex;
    border-top: 1px solid #f4f4f4;
    justify-content: space-between;
    padding: 16px 0 20px;
}

.chartFillterLeft {
    flex: 1;
    display: flex;
    align-items: center;
    padding-right: 15px;
}

.chartFillterRight {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.chartFillterRow .formControl {
    background: #ffffff;
    border: 1px solid #dfe3e9;
    border-radius: 4px;
    max-width: 232px;
    width: 100%;
    height: 36px;
    flex: 1 1 auto;
    color: #354052;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    padding: 5px 13px;
}

.chartFillterLeft .formControl {
    max-width: 75px;
}

.chartFillterLeft label {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #23252b;
    margin-left: 10px;
}

.chartFillterRow .btnFiltterSave {
    background-image: linear-gradient(180deg, #fe7652 0%, #e95933 100%);
    background-size: 100% 100%;
    border: 0px;
    width: 89px;
    flex: 0 0 89px;
    height: 36px;
    color: #fff;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    border-radius: 4px;
    transition: all 0.5s ease-in-out;
    margin-left: 7px;
}

.chartFillterRow .btnFiltterSave:hover {
    opacity: 0.8;
}

.charteBox {
    padding: 0 30px 30px;
}

.charteBox img {
    max-width: 100%;
    height: auto;
    width: 100%;
}

.addPlayListBox {
    padding: 11px 15px 15px;
}

.addPlayListRow {
    background: #f7f9fd;
    border: 1px solid #f7f9fd;
    border-radius: 4px;
    padding: 28px 23px 29px 17px;
}

.addPlayListRow.active {
    border: 1px solid #cd4926;
}

.formGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 22px;
}

.formGroup label {
    width: 100%;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #3b4859;
    margin-bottom: 10px;
}

.formGroup .form-control {
    border-radius: 4px;
    background: #ffffff;
    width: 100%;
    height: 36px;
    padding: 5px 13px;
    border: 0px;
    color: #354052;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
}

.saveBtnRow {
    display: flex;
    align-items: center;
}

.saveBtnRow p {
    color: #00b546;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin-left: 12px;
    display: none;
}

.addPlayListRow.active .saveBtnRow p {
    display: block;
}

.addPlayListRow .saveBtn {
    background: #e7e7e7;
    border: 1px solid #b1b1b1;
    border-radius: 4px;
    cursor: pointer;
    width: 89px;
    height: 36px;
    color: #b1b1b1;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    transition: all 0.3s;
}

.addPlayListRow .saveBtn:hover {
    background: #b1b1b1;
    color: #666;
}

.addPlayListRow.active .saveBtn {
    background: linear-gradient(180deg, #fe7652 0%, #e95933 100%);
    color: #fff;
    border: 1px solid #c73914;
    transition: all 0.3s ease-in-out;
}

.addPlayListRow.active .saveBtn:hover {
    opacity: 0.8;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    opacity: 0.5;
}

::-moz-placeholder {
    /* Firefox 19+ */
    opacity: 0.5;
}

:-ms-input-placeholder {
    /* IE 10+ */
    opacity: 0.5;
}

:-moz-placeholder {
    /* Firefox 18- */
    opacity: 0.5;
}

.changeplayListRow {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.changeplayListRow .playListHead {
    padding: 20px 20px 19px;
    border-bottom: 1px solid #f4f4f4;
    display: flex;
    justify-content: space-between;
}

.playListHead .playIconList {
    display: flex;
    align-items: center;
}

.playIconList .controlBtn {
    position: relative;
    background: transparent;
    cursor: pointer;
    border: 0;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: grayscale(100%);
    transition: all 0.3s ease-in-out;
    outline: 0;
    margin-left: 20px;
}

.playIconList .controlBtn:hover {
    filter: grayscale(0);
}

.controlBtn img {
    max-width: 100%;
    height: auto;
    transition: all 0.3s ease-in-out;
}

.controlBtn img.playIcon {
    display: none;
}

.controlBtn img.pushIcon {
    display: block;
}

.controlBtn.play img.playIcon {
    display: block;
}

.controlBtn.play img.pushIcon {
    display: none;
}

.changeplayListRow .playListHead h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #3b4859;
}

.changeplayListRow .changePlayList {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: auto;
    padding: 0;
}

.changePlayList .changePlayListItem {
    padding: 15px 20px;
    display: flex;
    border-bottom: 1px solid #f4f4f4;
    position: relative;
    background-color: #fff;
}

.changePlayList .changePlayListItem:hover {
    background-color: rgba(168, 183, 197, 0.09);
}

.changePlayList .dragIcon {
    position: absolute;
    top: 50%;
    left: 5px;
    display: flex;
    margin-top: -6px;
    opacity: 0;
    transition: all 0.3s;
}

.changePlayList .changePlayListItem:hover .dragIcon {
    opacity: 1;
}

.changePlayList .changePlayListItem:last-child {
    border-bottom: 0px;
}

.changePlayListItem .changePlayListThumb {
    flex: 0 0 56px;
    width: 56px;
    height: 56px;
}

.changePlayListItem .changePlayListThumb img {
    height: auto;
    width: 100%;
    max-width: 100%;
}

.changePlayListItem .changeplayListName {
    flex: 1 1 auto;
    padding: 0 10px 0 20px;
    align-self: center;
}

.changePlayListItem .changeplayListName h3 {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: #1c1e25;
    margin: 0;
}

.changePlayListItem .changeplayListName p {
    color: #7f8fa4;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin: 0px;
}

.changePlayListItem .changeplayListTrack {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.changePlayListItem .changeplayListTrack p {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #1c1e25;
    margin-bottom: 12px;
}

.changePlayListItem .changeplayListTrack .deleteIcon {
    background-color: transparent;
    border: 0px;
    cursor: pointer;
}

.mobileDragHendle {
    display: none;
}
